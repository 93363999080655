import request from '@/utils/request'

// 所有区域包括站点、大队、团队 列表结构带查询
export function getTeamList(params, name) {
    return request({
        url: `/v2/api/app/ap_member/member/query/region/list?appName=${params.appName}&page=${params.page}&size=${params.size}&name=${name}`,
        method: 'get',
    })
}
// 申请加入团队
export function joinTeam(params) {
    return request({
        url: `/v2/api/app/ap_member/member/add/apply_region`,
        method: 'post',
        params
    })
}
// 我加入的团队
export function getMyTeam(params) {
    return request({
        url: `/v2/api/app/ap_member/member/query/my/group?memberId=${params.memberId}`,
        method: 'get'
    })
}
// 退出团队
export function quitMyTeam(params) {
    return request({
        url: `/v2/api/app/ap_member//member/update/cancel/group`,
        method: 'post',
        params
    })
}
export default { getTeamList, joinTeam, getMyTeam, quitMyTeam }