<template>
  <div class="titlesTop">
    <headTitle title="团队" />
    <mescroll-vue ref="mescroll" class="gundong" :down="mescrollDown" :up="mescrollUp">
      <van-search
        v-model="team_name"
        show-action
        shape="round"
        background="#efefef"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @clear="onClear"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div v-for="(items, index) in dataList" :key="index">
        <div class="team_box">
          <span>{{items.name}}</span>
          <div @click="applyToTeam(items)">申请加入</div>
        </div>
      </div>
      <div id="empty"></div>
    </mescroll-vue>
    <van-overlay :show="window_show">
      <div class="wrapper" @click.stop>
        <div class="success_block">
          <div class="success_head">
            <img src="../../assets/rightsign.png" alt />
          </div>
          <p>申请提交成功</p>
          <span>请等待管理员的审核</span>
          <button @click="window_show = false">我知道了</button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
import Teamapi from '@/api/team'
import { Toast } from 'vant'

export default {
  data() {
    return {
      mescroll: null,
      mescrollDown: {},
      mescrollUp: {
        callback: this.upCallback,
        page: {
          num: 0,
          size: 20
        },
        empty: {
          warpId: "empty",
          icon: "",
          tip: "暂无数据"
        }
      },
      params: {
        page: 1,
        sise: 10
      },
      team_name: '',
      dataList: [],
      window_show: false
    }
  },
  components: {
    MescrollVue
  },
  methods: {
    upCallback(page, mescroll) {
      this.params.page = page.num;
      this.params.size = page.size;
      this.params.appName = this.appName;
      Teamapi.getTeamList(this.params, this.team_name).then((res) => {
        let arr = res.data.items;
        if (page.num === 1) this.dataList = [];
        this.dataList = this.dataList.concat(arr);
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length);
          console.log(this.dataList);
        })
      }).catch((err) => {
        mescroll.endErr();
      })
    },
    onSearch() {
      Teamapi.getTeamList(this.params, this.team_name).then((res) => {
        this.dataList = res.data.items;
      }).catch((err) => {
        console.log(err);
      })
    },
    onClear() {
      this.onSearch();
    },
    // 申请加入团队
    applyToTeam(val) {
      if (this.isToken()) {
        // 调接口
        const userInfo = JSON.parse(window.localStorage.userinfo);
        const params = { regionId: val.keyId, memberId: userInfo.id };
        Teamapi.joinTeam(params).then((res) => {
          // Toast(val.keyId);
          // alert(res);
          if (res.success) {
            this.window_show = true;
          } else {
            if (res.code === 10001) {
              Toast('请勿重复申请!');
            }
            if (res.code === 40001) {
              Toast('请完善用户信息和特长!');
            }
          }
        }).catch((err) => {
          console.log(err);
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.team_box {
  padding: 2vw 4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 0.34rem;
  }
  div {
    background: #ff5831;
    color: #fff;
    padding: 1vw 2vw;
    border-radius: 5px;
  }
}
.wrapper {
  .success_block {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    background: #fff;
    width: 70vw;
    margin: 65% auto 0;
    border-radius: 10px;
    .success_head {
      border: 1px solid #e4edff;
      background: #fff;
      width: 30vw;
      height: 30vw;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -8vw;
    }
    p {
      font-size: 5vw;
      font-weight: bold;
      margin: 3vw 0;
    }
    span {
      font-size: 4vw;
      color: #9a9a9a;
    }
    button {
      border-radius: 35px;
      border: none;
      width: 40vw;
      height: 10vw;
      line-height: 10vw;
      text-align: center;
      background: #ff5128;
      color: #fff;
      font-size: 4vw;
      margin: 5vw 0;
    }
  }
}
</style>